<template>
  <pp-card
    v-if="state.is('first-load')"
    class="border flex flex-row flex-wrap divide-y sm:divide-y-0"
  >
    <pp-stat class="w-full sm:w-1/2 sm:!border-b" header="Liquidity">
      <template v-slot:content>
        <pp-skeleton-loader class="h-10"></pp-skeleton-loader>
      </template>
    </pp-stat>

    <pp-stat class="w-full sm:w-1/2 sm:border-l sm:!border-b" header="Volume (24h)">
      <template v-slot:content>
        <pp-skeleton-loader class="h-10"></pp-skeleton-loader>
      </template>
    </pp-stat>

    <pp-stat class="w-full sm:w-1/2" header="LP APR">
      <template v-slot:content>
        <pp-skeleton-loader class="h-10"></pp-skeleton-loader>
      </template>
    </pp-stat>

    <pp-stat class="w-full sm:w-1/2 sm:border-l" header="Yield Rate(Annual)">
      <template v-slot:content>
        <pp-skeleton-loader class="h-10"></pp-skeleton-loader>
      </template>
    </pp-stat>
  </pp-card>

  <pp-card
    v-else-if="state.is('loaded-once')"
    class="border flex flex-row flex-wrap divide-y sm:divide-y-0"
  >
    <pp-stat class="w-full sm:w-1/2 sm:!border-b" header="Liquidity">
      <template v-slot:content>
        <pp-formatted-price
          :currency="state.response.otherDetails.liquidity.currency"
          :value="state.response.otherDetails.liquidity.amount"
          data-test="liquidity"
        ></pp-formatted-price>
      </template>

      <template v-slot:subcontent>
        <div :class="textColor(state.response.otherDetails.liquidity24HChange)">
          <div class="inline-block text-xs mr-1">
            <span v-text="textSymbol(state.response.otherDetails.liquidity24HChange)"></span>
          </div>

          <pp-formatted-number
            :value="100 * state.response.otherDetails.liquidity24HChange"
            :min-decimal="2"
            :max-decimal="2"
            data-test="liquidity-change"
          ></pp-formatted-number>
          <span>%</span>
        </div>
      </template>
    </pp-stat>

    <pp-stat class="w-full sm:w-1/2 sm:border-l sm:!border-b" header="Volume (24h)">
      <template v-slot:content>
        <pp-formatted-price
          :currency="state.response.otherDetails.dailyVolume.currency"
          :value="state.response.otherDetails.dailyVolume.amount"
          data-test="volume"
        ></pp-formatted-price>
      </template>

      <template v-slot:subcontent>
        <div :class="textColor(state.response.otherDetails.volume24hChange)">
          <div class="inline-block text-xs mr-1">
            <span v-text="textSymbol(state.response.otherDetails.volume24hChange)"></span>
          </div>

          <pp-formatted-number
            :value="100 * state.response.otherDetails.volume24hChange"
            :min-decimal="2"
            :max-decimal="2"
            data-test="volume-change"
          ></pp-formatted-number>
          <span>%</span>
        </div>
      </template>
    </pp-stat>

    <pp-stat class="w-full sm:w-1/2" header="LP APR">
      <template v-slot:content>
        <pp-simple-data class="flex font-normal text-lg">
          <template #left>PENDLE APR</template>

          <template #right>
            <pp-skeleton-loader
              v-if="pendleAprState.is('loading')"
              class="w-20 h-6"
            ></pp-skeleton-loader>

            <div v-else-if="pendleAprState.is('resolved')">
              <pp-formatted-number :value="pendleApr"></pp-formatted-number>
              <span>%</span>
            </div>

            <pp-btn
              v-else-if="pendleAprState.is('rejected')"
              variant="accent"
              color="p-red"
              @click="fetchPendleApr"
            >
              Retry
            </pp-btn>
          </template>
        </pp-simple-data>

        <pp-simple-data class="flex font-normal text-lg">
          <template #left>Swap Fee APR</template>

          <template #right>
            <pp-formatted-number
              :value="100 * state.response.otherDetails.swapFeeApr"
              data-test="swap-fee-apr"
            ></pp-formatted-number>
            <span>%</span>
          </template>
        </pp-simple-data>
      </template>
    </pp-stat>

    <pp-stat class="w-full sm:w-1/2 sm:border-l" header="Yield Rate(Annual)">
      <template v-slot:content>
        <pp-simple-data class="font-normal text-lg">
          <template #left>
            Implied Yield
            <pp-tooltip-trigger direction="bottom" translate="right">
              The annual yield on the underlying asset that the market is implying, by trading YT at
              the current price.
            </pp-tooltip-trigger>
          </template>
          <template #right>
            <pp-formatted-number
              :value="100 * state.response.otherDetails.impliedYield"
              data-test="implied-yield"
            ></pp-formatted-number>
            <span>%</span>
          </template>
        </pp-simple-data>

        <pp-simple-data class="font-normal text-lg">
          <template #left>
            <span v-text="market.tokenIssuer.yieldBearingToken.protocol"></span>
          </template>

          <template #right>
            <pp-formatted-number
              :value="100 * state.response.otherDetails.underlyingYieldRate"
              data-test="underlying-yield"
            ></pp-formatted-number>
            <span>%</span>
          </template>
        </pp-simple-data>
      </template>
    </pp-stat>
  </pp-card>

  <pp-alert v-else-if="state.is('rejected')" color="p-red">
    Unable to fetch market data. Please try again in a few minutes.

    <template v-slot:actions>
      <pp-btn variant="accent" @click="fetchDetails">Retry</pp-btn>
    </template>
  </pp-alert>
</template>

<script>
  import Vue from 'vue'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import StakingPool from '@/domains/entities/StakingPool'
  import MerkleDistributor from '@/domains/entities/MerkleDistributor'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    props: {
      address: { type: String, required: true },
    },
    data() {
      return {
        state: createState(),
        pendleAprState: createState({ response: [] }),
      }
    },
    watch: {
      address: {
        immediate: true,
        handler() {
          this.fetchDetails()
        },
      },
    },
    computed: {
      market() {
        return PendleAmmMarket.query().find(this.address)
      },
      stakingPool() {
        const stakingPools = StakingPool.query().where('inputToken.address', this.market.address)
        return stakingPools[0]
      },
      pendleApr() {
        const aprs = this.pendleAprState.response
        const found = aprs.find((apr) => apr.origin.toUpperCase() === 'PENDLE')
        return 100 * (found.apr || 0)
      },
      isMainnet() {
        return sdkChainId === 1
      },
    },
    methods: {
      textColor(val) {
        return (
          (val == 0 && 'text-p-gray-600') || (val > 0 && 'text-p-green-600') || 'text-p-red-600'
        )
      },
      textSymbol(val) {
        return (val == 0 && ' ') || (val > 0 && '▲') || '▼'
      },
      fetchMarketDetails() {
        return new PromiseHandler(
          () => this.market.contract(this.$store.getters['wallet/identity']).readMarketDetails(),
          this.state
        )
          .execute()
          .catch((err) => {
            console.error('Error fetching market details', err)
          })
      },
      fetchStakingPoolRewardAprs() {
        return this.stakingPool
          .contract(this.$store.getters['wallet/identity'])
          .rewardAprs()
      },
      fetchMerkleDistributorRewardApr() {
        const merkleDistributor = new MerkleDistributor({ chainId: 1 })
        return merkleDistributor
          .contract(this.$store.getters['wallet/identity'])
          .rewardAPR(this.market.token)
      },
      fetchPoolRewardApr() {
        return this.isMainnet
          ? this.fetchMerkleDistributorRewardApr()
          : this.fetchStakingPoolRewardAprs()
      },
      fetchPendleApr() {
        new PromiseHandler(this.fetchPoolRewardApr, this.pendleAprState).execute()
      },
      fetchDetails() {
        this.fetchMarketDetails()
        this.fetchPendleApr()
      },
    },
    created() {
      const interval = setInterval(this.fetchDetails, 60000)
      this.$once('hook:beforeDestroy', () => clearInterval(interval))
    },
  })
</script>

<template>
  <pp-card class="space-y-5 overflow-auto">
    <div class="p-2">
      <pp-tab
        v-for="filter in filters"
        :key="filter"
        v-text="filter"
        class="rounded font-normal"
        size="lg"
        @click="activeFilter = filter"
        variant="text"
        :active="activeFilter === filter"
        :data-test="`${filter}-transactions-filter`"
      ></pp-tab>
    </div>

    <pp-table class="min-w-[800px]">
      <thead>
        <tr>
          <pp-th>Action</pp-th>
          <pp-th align="right">Value</pp-th>
          <pp-th>Token In</pp-th>
          <pp-th>Token Out</pp-th>
          <pp-th align="right">Time</pp-th>
        </tr>
      </thead>

      <pp-fade-transition hide-on-leave>
        <transition-group
          :key="`${page}-${activeFilter}`"
          tag="tbody"
          class="relative divide-y-2"
          enter-active-class="transition duration-500 ease-in transform"
          leave-active-class="transition duration-500 transform absolute right-0 left-0 w-full"
          enter-class="-translate-y-5 bg-p-blue-200"
          leave-to-class="opacity-0 scale-0"
          move-class="transform transition duration-500"
        >
          <transaction-table-item
            v-for="transaction in currentPageTransactions"
            :transaction="transaction"
            :key="transaction.id"
          ></transaction-table-item>
        </transition-group>
      </pp-fade-transition>
    </pp-table>

    <pp-card-actions>
      <div class="flex justify-center items-center space-x-8">
        <div class="flex items-center space-x-2">
          <base-button
            @click="toFirstPage"
            :disabled="isBrowsingFirstPage"
            class="p-1 focus:outline-none disabled:text-p-gray-300"
          >
            <svg
              class="h-4 w-4"
              viewBox="0 0 9 10"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>First Page</title>
              <path d="M8.29156 1.20886L3.93848 4.91035L8.29156 8.61185" />
              <path d="M1.21777 0.746216V9.07457" />
            </svg>
          </base-button>

          <base-button
            @click="toPrevPage"
            :disabled="isBrowsingFirstPage"
            class="p-1 focus:outline-none disabled:text-p-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <title>Previous Page</title>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </base-button>
        </div>

        <div v-text="`${page + 1} of ${paginatedTransactions.length}`"></div>

        <div class="flex items-center space-x-2">
          <base-button
            @click="toNextPage"
            :disabled="isBrowsingLastPage"
            class="p-1 focus:outline-none disabled:text-p-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <title>Next Page</title>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </base-button>

          <base-button
            @click="toLastPage"
            :disabled="isBrowsingLastPage"
            class="p-1 focus:outline-none disabled:text-p-gray-300"
          >
            <svg
              class="h-4 w-4"
              viewBox="0 0 9 10"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Last Page</title>
              <path d="M1.25239 8.61194L5.60547 4.91045L1.25239 1.20895" />
              <path d="M8.32617 9.07458L8.32617 0.746227" />
            </svg>
          </base-button>
        </div>
      </div>
    </pp-card-actions>
  </pp-card>
</template>

<script>
  import TransactionTableItem from './TransactionTableItem.vue'
  import BaseButton from '@/components/base/BaseButton.vue'

  const headers = ['Action', '', 'Value', 'Token Amount', 'Token Amount', 'Account', 'Time']

  const filterMapping = {
    All: (txn) => true,
    'Mints/Redeems': (txn) => txn.filter === 'Mints/Redeems',
    Swaps: (txn) => txn.filter === 'Swaps',
    Liquidity: (txn) => txn.filter === 'Liquidity',
  }

  const chunk = (arr, size) => {
    return arr.reduce(
      (rows, key, index) =>
        (index % size == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows,
      []
    )
  }

  const pageSize = 5

  export default {
    props: {
      transactions: { type: Array, default: () => [] },
    },
    components: {
      TransactionTableItem,
      BaseButton,
    },
    data() {
      return {
        headers,
        filters: Object.keys(filterMapping),
        activeFilter: 'All', // All, Mints/Redeems, Swaps, Adds/Removes
        page: 0,
      }
    },
    watch: {
      activeFilter() {
        this.toFirstPage()
      },
    },
    methods: {
      toPrevPage() {
        if (!this.isBrowsingFirstPage) this.page--
      },
      toNextPage() {
        if (!this.isBrowsingLastPage) this.page++
      },
      toFirstPage() {
        this.page = 0
      },
      toLastPage() {
        this.page = this.paginatedTransactions.length - 1
      },
    },
    computed: {
      isBrowsingFirstPage() {
        return this.page === 0
      },
      isBrowsingLastPage() {
        return this.page === this.paginatedTransactions.length - 1
      },
      filteredTransactions() {
        const { activeFilter, transactions } = this
        return transactions.filter(filterMapping[activeFilter])
      },
      paginatedTransactions() {
        return chunk(this.filteredTransactions, pageSize)
      },
      currentPageTransactions() {
        return this.paginatedTransactions[this.page]
      },
    },
  }
</script>

<template>
  <div class="space-y-2">
    <div v-for="entry in formattedEntries" :key="entry.token.address">
      <div
        :title="entry.token.name"
        class="inline-flex bg-p-gray-100 rounded-lg p-1 px-2 text-center space-x-2 items-center"
      >
        <pp-token-icon :token="entry.token" class="h-6 w-6 rounded-full border-p-gray-900" />
        <pp-formatted-number :value="entry.rawAmount()" />
      </div>
    </div>
  </div>
</template>

<script>
  const activeEntries = (entries) => entries.filter((entry) => entry.rawAmount() > 0)

  export default {
    props: {
      entries: { type: Array, default: () => [] },
    },
    computed: {
      formattedEntries() {
        return activeEntries(this.entries)
      },
    },
  }
</script>

<template>
  <div class="grid gap-3 grid-cols-12">
    <div class="col-span-12 md:col-span-7">
      <pp-select
        v-model="marketPair"
        :options="marketPairs"
        btn-class="bg-white shadow-pendle border border-p-gray-200"
        data-test="pair-selector-dropdown"
      >
        <template v-slot:default>
          <pp-market-preview
            v-if="value"
            :address="value.address"
            title-class="md:text-xl"
            data-test="pair-selector"
          ></pp-market-preview>
          <div v-else>Select</div>
        </template>

        <template v-slot:option="{ option, on, active }">
          <pp-tab
            class="block w-full text-left"
            v-on="on"
            :active="active"
            v-text="option.pair"
            :data-test="`select-${option.pair}`"
          ></pp-tab>
        </template>
      </pp-select>
    </div>

    <div class="col-span-12 md:col-span-5">
      <pp-select
        v-model="selectedExpiry"
        :options="expiryOptions"
        btn-class="bg-white shadow-pendle border border-p-gray-200"
        data-test="expiry-selector-dropdown"
      >
        <template v-slot:default>
          <template v-if="value">
            <div class="text-left">
              <div>
                <pp-formatted-date
                  :value="value.expiresAt"
                  class="md:text-xl"
                  data-test="expiry-selector"
                ></pp-formatted-date>
              </div>

              <div>
                <div v-if="selectedMarket.statusIs('expired')" class="font-normal text-p-red-600">
                  Expired
                </div>

                <pp-relative-time
                  v-else
                  class="font-normal"
                  :class="
                    selectedMarket.statusIs('frozen') ? 'text-p-yellow-500' : 'text-p-green-600'
                  "
                  format="countdown"
                  :value="value.expiresAt"
                  :significant-units="3"
                  data-test="countdown"
                ></pp-relative-time>
              </div>
            </div>
          </template>

          <div v-else>Select</div>
        </template>

        <template v-slot:option="{ option, on, active }">
          <pp-tab class="block w-full text-left" v-on="on" :active="active">
            <pp-formatted-date
              :value="option.market.expiresAt"
              :data-test="`select-${option.market.expiresAt}`"
            ></pp-formatted-date>
          </pp-tab>
        </template>
      </pp-select>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'

  const markets = PendleAmmMarket.query()

  export default Vue.extend({
    props: {
      value: { type: Object, default: () => null },
    },
    data() {
      return {
        markets,
        marketPair: this.$props.value ? this.$props.value.pairName : null,
        selectedExpiry: this.$props.value ? this.$props.value.expiryEpoch : null,
      }
    },
    watch: {
      marketPair() {
        const nonExpiredOption = this.expiryOptions.find(option => option.market.status !== 'expired')
        this.selectedExpiry = nonExpiredOption?.value || this.expiryOptions[0].value
      },
      selectedMarket(val) {
        this.$emit('input', val)
      },
    },
    computed: {
      selectedMarket() {
        return this.marketPairs
          .find((marketPair) => marketPair.pair === this.marketPair)
          ?.markets.find((market) => market.expiryEpoch === this.selectedExpiry)
      },
      marketPairs() {
        const marketGroup = this.markets.reduce((group, market) => {
          group[market.token.symbol] = group[market.token.symbol] || []
          group[market.token.symbol].push(market)
          return group
        }, {})

        return Object.keys(marketGroup).map((pairName) => ({
          pair: pairName,
          value: pairName,
          text: pairName,
          key: pairName,
          markets: marketGroup[pairName],
        }))
      },
      expiryOptions() {
        const marketPair = this.marketPairs.find((pair) => pair.pair === this.marketPair)

        return marketPair
          ? marketPair.markets.map((market) => ({
              market,
              value: market.expiryEpoch,
              text: new Intl.DateTimeFormat('en-US', {
                dateStyle: 'medium',
                timeZone: 'UTC',
              }).format(market.expiresAt),
              key: market.expiryEpoch,
            }))
          : []
      },
    },
  })
</script>

<template>
  <pp-section header="Transactions">
    <div>
      <pp-card v-if="transactions.is('first-load')">
        <pp-card-body>
          <div class="flex space-x-5">
            <pp-skeleton-loader v-for="n in 4" :key="n" class="w-20 h-6"></pp-skeleton-loader>
          </div>

          <div class="mt-10 space-y-8">
            <pp-skeleton-loader v-for="n in 5" :key="n" class="h-6"></pp-skeleton-loader>
          </div>
        </pp-card-body>
      </pp-card>

      <keep-alive v-else>
        <TransactionTable :transactions="transactions.response" />
      </keep-alive>
    </div>
  </pp-section>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import Transaction from '@/domains/entities/Transaction'
  import TransactionTable from './tables/transactions/TransactionTable.vue'

  export default Vue.extend({
    components: { TransactionTable },
    props: {
      address: { type: String },
    },
    data() {
      return {
        transactions: createState({ response: [] }),
      }
    },
    watch: {
      address: {
        immediate: true,
        handler() {
          this.fetchTransactions()
        },
      },
    },
    methods: {
      fetchTransactions() {
        new PromiseHandler(
          () => Transaction.getAllTransactions(this.address),
          this.transactions,
        ).execute()
      },
    },
    created() {
      const interval = setInterval(this.fetchTransactions, 60000)
      this.$once('hook:beforeDestroy', () => clearInterval(interval))
    },
  })
</script>

<template>
  <tr>
    <pp-td class="whitespace-nowrap text-p-blue-500">
      <a
        class="hover:underline"
        :href="generateEtherscanTxUrl(transaction.hash)"
        target="_blank"
        rel="noopener noreferrer"
        data-test="transaction-action"
      >
        {{ description }}
      </a>
    </pp-td>
    <pp-td align="right" class="whitespace-nowrap">
      <pp-formatted-price
        :value="transaction.valuation.amount"
        :currency="transaction.valuation.currency"
      />
    </pp-td>
    <pp-td class="text-sm">
      <transaction-amount-entries-container
        :entries="transaction.paid"
      ></transaction-amount-entries-container>
    </pp-td>

    <pp-td class="text-sm">
      <transaction-amount-entries-container
        :entries="transaction.received"
      ></transaction-amount-entries-container>
    </pp-td>
    <pp-td align="right">
      <pp-relative-time
        :value="date"
        format="countdown"
        :significant-units="1"
        :absolute="true"
      />
    </pp-td>
  </tr>
</template>

<script>
  import {
    activeTokens,
    toSentence,
    generateEtherscanTxUrl,
    generateEtherscanAddressUrl,
  } from '@/assets/helpers'

  import TransactionAmountEntriesContainer from './TransactionAmountEntriesContainer.vue'

  const readableCurrency = (entries) =>
    toSentence(activeTokens(entries).map((tokenAmount) => tokenAmount.token.symbol))

  const descriptionMapping = {
    Add: (txn) => 'Add liquidity',
    Remove: (txn) => 'Remove liquidity',
    Mint: (txn) => `Mint with ${readableCurrency(txn.paid)}`,
    Redeem: (txn) => `Redeem ${readableCurrency(txn.received)}`,
    Swap: (txn) => `Swap ${readableCurrency(txn.paid)} for ${readableCurrency(txn.received)}`,
  }

  export default {
    props: {
      transaction: { type: Object, required: true },
    },
    components: {
      TransactionAmountEntriesContainer,
    },
    computed: {
      description() {
        const { transaction } = this
        return descriptionMapping[transaction.action](transaction)
      },
      date() {
        // convert from UNIX timestamp
        return new Date(this.transaction.timestamp * 1000)
      },
    },
    methods: {
      generateEtherscanTxUrl,
      generateEtherscanAddressUrl,
    },
  }
</script>

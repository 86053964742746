<template>
  <pp-card class="border p-2">
    <pp-card-title class="font-semibold text-2xl text-p-blue-800">
      <div v-if="currentPrice">
        <pp-formatted-number
          :currency="currentPrice.price.currency"
          :value="currentPrice.price.amount"
          :humanize="false"
          :min-decimal="2"
          :max-decimal="currentPricePrecision"
        ></pp-formatted-number>
      </div>
      <pp-skeleton-loader v-else class="h-8 w-32"></pp-skeleton-loader>
    </pp-card-title>

    <pp-card-subtitle class="text-water-600">
      <span class="font-semibold text-p-blue-800" data-test="chart-ticker">
        1 {{ market.yt.symbol }}
      </span>
      represents the yield of
      <div class="inline font-semibold text-p-blue-800">
        <pp-formatted-number
          :value="market.yt.yieldRepresentation.amount"
          :min-decimal="0"
          :max-decimal="7"
          data-test="yt-conversion-rate"
        ></pp-formatted-number>
        {{ market.yt.yieldRepresentation.unit }} on {{ market.yt.yieldRepresentation.protocol }}
      </div>
      <pp-tooltip-trigger direction="top" width="max">
        <loading-icon v-if="marketDetailsState.is('loading')" class="h-6 w-6" />
        <template v-else>
          <div class="min-w-max font-bold text-center">
            <pp-formatted-number
              :value="market.yt.yieldRepresentation.amount"
              :min-decimal="0"
              :max-decimal="7"
            ></pp-formatted-number>
            {{ market.yt.yieldRepresentation.unit }} =
            <pp-formatted-price
              :value="underlyingPrice.amount"
              :currency="underlyingPrice.currency"
            />
          </div>
        </template>
      </pp-tooltip-trigger>
    </pp-card-subtitle>

    <pp-card-subtitle class="flex items-center">
      <pp-tab-container>
        <pp-tab @click="chartType = 'hourly'" :active="chartType === 'hourly'">Hourly</pp-tab>
        <pp-tab @click="chartType = 'daily'" :active="chartType === 'daily'">Daily</pp-tab>
      </pp-tab-container>

      <div class="flex-grow"></div>
      <loading-icon v-if="priceData.is('loading')" class="h-6 w-6" />
    </pp-card-subtitle>

    <pp-card-body>
      <div id="chart-canvas" ref="chartCanvas" class="overflow-auto">
        <pp-skeleton-loader
          class="rounded-xl"
          :style="{ height: `${chartHeight}px` }"
        ></pp-skeleton-loader>
      </div>
    </pp-card-body>
  </pp-card>
</template>

<script>
  import Vue from 'vue'
  import { PendleQuerySet } from '@pendle/subgraph-sdk'
  import { createChart, CrosshairMode } from 'lightweight-charts'
  import { sdkChainId } from '@/app-config/network'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import LoadingIcon from './svg/LoadingIcon.vue'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'

  const chartHeight = 300
  const chartFitContentLimit = 80

  export default Vue.extend({
    components: { LoadingIcon },
    props: {
      address: { type: String, required: true },
    },
    data() {
      return {
        chart: null,
        chartType: 'daily',
        priceData: createState(),
        candleStickSeries: null,
        currentPrice: null,
        chartHeight,
        marketDetailsState: createState({
          response: {
            otherDetails: {
              underlyingPrice: { currency: 'USD', amount: '0' },
            },
          },
        }),
      }
    },
    watch: {
      key() {
        this.candleStickSeries.setData([])
        this.fetchPriceData()
      },
    },
    computed: {
      key() {
        return `${this.address}-${this.chartType}`
      },
      market() {
        return PendleAmmMarket.query().find(this.address)
      },
      currentPricePrecision() {
        return this.currentPrice.price.amount.toString().split('.')[0].length > 2 ? 2 : 7
      },
      underlyingPrice() {
        return this.marketDetailsState.response.otherDetails.underlyingPrice
      },
    },
    methods: {
      resetChartSize() {
        if (this.chart) {
          this.chart.applyOptions({
            width: this.$refs.chartCanvas.clientWidth,
          })
        }
      },
      fetchMarketDetails() {
        new PromiseHandler(
          () => this.market.contract(this.$store.getters['wallet/identity']).readMarketDetails(),
          this.marketDetailsState
        ).execute()
      },
      fetchPriceData() {
        const pendleQuerySet = new PendleQuerySet(sdkChainId)

        return new PromiseHandler(
          () =>
            Promise.all([
              pendleQuerySet.getCandlestickDatas(this.address, this.chartType, 1000),
              this.market.contract(this.$store.getters['wallet/identity']).readMarketDetails(),
            ]),
          this.priceData
        )
          .execute()
          .then(([candleStickResponse, marketDetails]) => {
            this.createChart()
            const latestPrice = { price: marketDetails.otherDetails.YTPrice, time: new Date() }
            this.currentPrice = latestPrice

            this.candleStickSeries.setData(
              candleStickResponse.map((market, index) => ({
                time: market.startTimestamp,
                open: parseFloat(market.yieldTokenPrice_open.toFixed()),
                high: parseFloat(market.yieldTokenPrice_high.toFixed()),
                low: parseFloat(market.yieldTokenPrice_low.toFixed()),
                close: parseFloat(
                  candleStickResponse.length - 1 === index
                    ? latestPrice.price.amount
                    : market.yieldTokenPrice_close.toFixed()
                ),
              }))
            )

            if (candleStickResponse.length < chartFitContentLimit) {
              this.chart.timeScale().fitContent()
            } else {
              const epochTimestamps = candleStickResponse
                .slice(-chartFitContentLimit)
                .map((stick) => stick.startTimestamp)
              this.chart.timeScale().setVisibleRange({
                from: Math.min(...epochTimestamps),
                to: Math.max(...epochTimestamps),
              })
            }

            this.chart.timeScale().scrollToRealTime()
          })
      },
      createChart() {
        const chartCanvas = this.$refs.chartCanvas
        chartCanvas.innerHTML = ''

        this.chart = createChart(
          chartCanvas,
          {
            width: chartCanvas.clientWidth,
            height: chartHeight,
            layout: {
              backgroundColor: '#ffffff',
              textColor: 'rgba(33, 56, 77, 1)',
            },
            grid: {
              horzLines: {
                visible: false,
              },
              vertLines: {
                visible: false,
              },
            },
            timeScale: {
              timeVisible: true,
              secondsVisible: false,
            },
            crosshair: {
              mode: CrosshairMode.Normal,
            },
          },
          100
        )

        this.candleStickSeries = this.chart.addCandlestickSeries({
          symbol: '$PENDLE',
          priceFormat: {
            type: 'price',
            precision: 6,
            minMove: 0.000001,
          },
        })
      },
    },
    mounted() {
      this.fetchPriceData()
      this.fetchMarketDetails()
      const interval = setInterval(() => {
        this.fetchPriceData()
        this.fetchMarketDetails()
      }, 60000)
      window.addEventListener('resize', this.resetChartSize)

      this.$once('hook:beforeDestroy', () => {
        clearInterval(interval)
        window.removeEventListener('resize', this.resetChartSize)
      })
    },
  })
</script>

<style>
  #chart-canvas canvas {
    z-index: auto !important;
  }
</style>

<template>
  <div class="my-4 container mx-auto">
    <div v-if="selectedMarket" class="space-y-4" :key="selectedMarket.address">
      <MarketSelect class="lg:hidden" v-model="selectedMarket" />

      <div class="flex flex-wrap lg:flex-nowrap">
        <div class="w-full lg:flex-grow lg:min-w-1 order-3 lg:order-1 space-y-4">
          <MarketSelect class="hidden lg:grid" v-model="selectedMarket" />

          <div class="space-y-4">
            <MarketPriceChart :address="selectedMarket.address" />
            <MarketDetailsCard :address="selectedMarket.address" />
            <MarketTransactionTable :address="selectedMarket.address" />
          </div>
        </div>

        <div class="ml-4 order-2"></div>

        <div class="flex-shrink-0 w-full lg:w-[450px] order-1 lg:order-3">
          <frozen-or-expired-alert-card
            v-if="selectedMarket.statusIs('inactive')"
            :market="selectedMarket"
            class="mb-5"
          >
            <template #actions>
              <pp-link
                :to="{
                  name: 'StakeIndex',
                  params: { inputTokenAddress: selectedMarket.address },
                }"
                text="Unstake here"
                class="text-water-600"
              ></pp-link>
              <pp-link
                :to="{
                  name: 'RemoveLiquidity',
                  params: { inputTokenAddress: selectedMarket.address },
                }"
                text="Remove liquidity here"
                class="text-water-600"
              ></pp-link>
            </template>
          </frozen-or-expired-alert-card>

          <pp-card class="border border-p-gray-200">
            <div class="flex space-x-1 p-1">
              <pp-tab
                data-test="action-tab"
                v-for="tab in tabs"
                :key="tab.text"
                size="lg"
                :tag="!tab.disabled ? 'router-link' : 'button'"
                variant="text"
                :to="{ name: tab.routeName, params: $route.params }"
                border="bottom"
                :active="$route.matched.map((route) => route.name).includes(tab.routeName)"
                :disabled="tab.disabled"
              >
                {{ tab.text }}
              </pp-tab>
            </div>
            <hr class="-mt-1" />

            <div class="mt-1" :key="$route.path">
              <router-view></router-view>
            </div>
          </pp-card>
        </div>
      </div>
    </div>

    <div v-else>
      <PpNotFound />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
  import MarketSelect from '@/components/MarketSelect'
  import MarketDetailsCard from '@/components/MarketDetailsCard.vue'
  import MarketPriceChart from '@/components/MarketPriceChart.vue'
  import MarketTransactionTable from '@/components/MarketTransactionTable.vue'
  import FrozenOrExpiredAlertCard from '@/components/FrozenOrExpiredAlertCard.vue'

  export default Vue.extend({
    components: {
      MarketSelect,
      MarketDetailsCard,
      MarketPriceChart,
      MarketTransactionTable,
      FrozenOrExpiredAlertCard,
    },
    data() {
      return {
        selectedMarket: this.getSelectedMarket()
      }
    },
    watch: {
      selectedMarket(newVal, oldVal) {
        if (oldVal !== undefined && newVal.address !== oldVal.address) {
          this.$router.push({
            name: 'TradeShow',
            params: { tokenAddress1: newVal.pair[0], tokenAddress2: newVal.pair[1] },
          })
        }
      },
      routeParams() {
        if (this.selectedMarket === undefined) {
          this.selectedMarket = this.getSelectedMarket()
        }
      },
    },
    computed: {
      routeParams() {
        return this.$route.params
      },
      tabs() {
        return [
          {
            text: 'Mint',
            routeName: 'MintIndex',
            disabled: false,
          },
          {
            text: 'Swap',
            routeName: 'Swap',
            disabled: this.selectedMarket.statusIs('inactive'),
          },
        ]
      },
    },
    methods: {
      getSelectedMarket() {
        const { tokenAddress1, tokenAddress2 } = this.$route.params
        return PendleAmmMarket.query().findBy('pair', (val) =>
          [tokenAddress1, tokenAddress2].every((tokenAddr) => val.includes(tokenAddr))
        )
      }
    }
  })
</script>
